import { BaseEntityModel } from '@common-src/model/base-model';
import { FormControl, FormControlType, FormControlTextModel, FormControlOptionModel, FormControlUploadModel, FormControlModel } from '@common-src/model/form-control';
import { QueryPageModel } from '@common-src/model/query-model';
import CommonService from '@common-src/service/common';
import { PropertyEntityType, ViewModeType } from '@common-src/model/enum';
import { getBizTypes } from '@common-src/service3/common.service';

export class WorkOrderConfigEntityModel extends BaseEntityModel {
    @FormControl({
        label: '服务名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    serviceName: string = undefined;

    @FormControl({
        label: '服务标识符',
        type: FormControlType.TEXT,
        required: true,
        readonly: true,
        pattern: /^[a-zA-z].*/,
        message: '标识符以字母开头'
    } as FormControlTextModel)
    servicePrefix: string = undefined;

    @FormControl({
        label: '工单类型',
        type: FormControlType.SELECT,
        optionsPromise: CommonService.getPropertyOptions,
        optionsPromiseParam: PropertyEntityType.WORK_ORDER_TYPE_CONF,
        required: true
    } as FormControlOptionModel)
    workOrderTypeId: string = undefined;
    workOrderTypeName: string = undefined;

    @FormControl({
        label: '子系统类型',
        type: FormControlType.SELECT,
        optionsPromise: getBizTypes,
        mode: 'multiple',
        required: false
    } as FormControlOptionModel)
    bizTypes: Array<any> = undefined;

    @FormControl({
        label: '关联表单',
        type: FormControlType.SELECT,
        optionsPromise: CommonService.getAllWorkOrderForms,
        required: true,
        readonly: true
    } as FormControlOptionModel)
    formId: string = undefined;
    formName: string = undefined;

    @FormControl({
        label: '关联流程',
        type: FormControlType.SELECT,
        optionsPromise: CommonService.getAvailableProcess,
        required: true,
        readonly: true
    } as FormControlOptionModel)
    processDefinitionKey: string = undefined;
    processDefinitionName: string = undefined;

    @FormControl({
        label: '谁可以创建',
        type: FormControlType.SELECT_USER
    } as FormControlModel<any>)
    authorizedIds: Array<any> = undefined;

    @FormControl({
        label: '是否启用',
        type: FormControlType.RADIO_GROUP,
        required: true,
        options: [
            { value: true, name: '是' },
            { value: false, name: '否' }
        ]
    } as FormControlOptionModel)
    active: boolean = true;

    // @FormControl({
    //     label: '显示图标',
    //     type: FormControlType.UPLOAD,
    //     listType: 'picture-card'
    // } as FormControlUploadModel)
    // icon: string = undefined;

    @FormControl({
        label: '描述',
        type: FormControlType.TEXT_AREA
    } as FormControlTextModel)
    description: string = undefined;

    canUpdate: boolean = true;

    static getTableColumns() {
        return [
            {
                title: '服务名称',
                dataIndex: 'serviceName'
            },
            // {
            //     title: '工单类型',
            //     dataIndex: 'workOrderTypeName'
            // },
            {
                title: '关联表单',
                dataIndex: 'formName'
            },
            {
                title: '关联流程',
                dataIndex: 'processDefinitionName'
            },
            {
                title: '是否启用',
                dataIndex: 'active',
                scopedSlots: { customRender: 'enabled' }
            },
            {
                title: '创建时间',
                dataIndex: 'createdTime'
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' },
                width: 400
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        const authorizedGroups = _.get(json, 'authorizedGroups');
        const authorizedUsers = _.map(_.get(json, 'authorizedUsers'), item => {
            item.isUser = true;
            return item;
        });
        this.authorizedIds = _.union(authorizedGroups, authorizedUsers);
        return this;
    }

    toService() {
        const data: any = super.toService();
        data.authorizedGroups = _.map(_.filter(this.authorizedIds, item => !item.isUser), item => {
            return { id: item.id, name: item.name };
        });
        data.authorizedUsers = _.map(_.filter(this.authorizedIds, item => item.isUser), item => {
            return { id: item.id, name: item.name };
        });
        delete data.authorizedIds;
        return data;
    }
}

export class WorkOrderConfigQueryModel extends QueryPageModel {
    keyWords: string = '';

    toService() {
        const data: any = super.toService();
        return data;
    }
}
